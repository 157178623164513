import axios from 'axios'
import { loginWithCredentials, verify2FAToken } from 'client/actions/auth/login'
import { useRedirect } from 'client/hooks/redirects'
import { useErrorToast } from 'modules/ui/toasts/useErrorToast'
import React from 'react'
import { Login2FAForm } from './2fa.form'
import { LoginCredentialsForm } from './credentials.form'

export const LoginController: React.FC = () => {
  const [show2FA, setShow2FA] = React.useState(false)
  const showCredentials = !show2FA
  const redirect = useRedirect('/dashboard')
  const errorToast = useErrorToast()

  const handleError = React.useCallback(
    (error: unknown) => {
      if (axios.isAxiosError(error)) {
        const e = new Error(error.response?.data.message)
        e.name = error.response?.data.error
        errorToast(e)
      } else {
        if (
          error instanceof Error &&
          error.message.includes('Session expired')
        ) {
          error.name = 'Timeout'
          setShow2FA(false) // Return to credentials
        }
        errorToast(error)
      }
    },
    [errorToast]
  )

  return (
    <>
      {showCredentials && (
        <LoginCredentialsForm
          onSubmit={async credentials => {
            try {
              const { require2FA } = await loginWithCredentials(credentials)
              if (require2FA) {
                setShow2FA(true)
              } else {
                await redirect()
              }
            } catch (error) {
              handleError(error)
            }
          }}
        />
      )}
      {show2FA && (
        <Login2FAForm
          onSubmit={async ({ token }) => {
            try {
              await verify2FAToken(token)
              await redirect()
            } catch (error) {
              handleError(error)
            }
          }}
        />
      )}
    </>
  )
}
