import { LoginController } from 'client/components/auth/login/login.controller'
import { AuthCardLayout } from 'client/layouts/authCardLayout'
import { Page } from 'client/layouts/pages/page'
import { NextPage } from 'next'
import React from 'react'

const LoginPage: NextPage = () => {
  return (
    <Page title="Log in">
      <AuthCardLayout>
        <LoginController />
      </AuthCardLayout>
    </Page>
  )
}

export default LoginPage
