import { decryptString as decloakString } from '@47ng/cloak'
import { base64toUTF8, utf8ToBase64 } from '@47ng/codec'
import { api } from 'client/services/api'
import { keystore } from 'client/state/stores/keystore'
import { deriveMasterKey } from 'modules/crypto/masterKey'
import {
  clientAssembleLoginResponse,
  clientVerifyLogin
} from 'modules/crypto/srp.client'
import type { Login2FATraits } from 'server/routes/auth/login/2fa'
import type {
  LoginSRPChallengeReply,
  LoginSRPChallengeRequest
} from 'server/routes/auth/login/challenge'
import type {
  LoginSRPResponseReply,
  LoginSRPResponseRequest
} from 'server/routes/auth/login/response'
import type { KeychainReply } from 'server/routes/keychain'
import { getUserAuthentication } from './getUserAuthentication'

export interface LoginCredentials {
  email: string
  password: string
}

export async function loginWithCredentials({
  email,
  password
}: LoginCredentials) {
  type SRPChallengeTraits = {
    Body: LoginSRPChallengeRequest
    Reply: LoginSRPChallengeReply
  }
  type SRPResponseTraits = {
    Body: LoginSRPResponseRequest
    Reply: LoginSRPResponseReply
  }

  // SRP Challenge --
  const challengeReply = await api.post<SRPChallengeTraits>(
    '/auth/login/challenge',
    {
      username: email
    }
  )
  const { userID, challengeID } = challengeReply.data
  const { ephemeral: clientEphemeral, session } =
    await clientAssembleLoginResponse(
      email,
      password,
      challengeReply.data.srpSalt,
      challengeReply.data.ephemeral
    )

  // SRP Response --
  const responseReply = await api.post<SRPResponseTraits>(
    '/auth/login/response',
    {
      userID,
      challengeID,
      ephemeral: clientEphemeral.public,
      proof: session.proof
    }
  )
  const { proof, masterSalt } = responseReply.data
  clientVerifyLogin(proof, clientEphemeral, session)
  if (!masterSalt) {
    // Store credentials in the keystore to recompose the master key
    // after 2FA verification.
    keystore.set(
      'credentials',
      encodeCredentials({ email, password }),
      Date.now() + 60_000
    )
    return {
      require2FA: true
    }
  }
  const { claims } = getUserAuthentication()
  if (!claims) {
    throw new Error('Session expired, please log in again')
  }
  const masterKey = await deriveMasterKey(email, password, masterSalt)
  const keychainReply = await api.get<{ Reply: KeychainReply }>('/keychain')
  const keychainKey = await decloakString(keychainReply.data.key, masterKey)
  keystore.set('keychainKey', keychainKey, claims.sessionExpiresAt)
  return {
    require2FA: false
  }
}

export async function verify2FAToken(token: string) {
  const credentials = keystore.get('credentials')
  if (!credentials) {
    throw new Error('Session expired, please log in again')
  }
  const { email, password } = decodeCredentials(credentials)
  const reply = await api.post<Login2FATraits>('/auth/login/2fa', {
    twoFactorToken: token,
    clientTime: Date.now()
  })
  const { claims } = getUserAuthentication()
  if (!claims) {
    throw new Error('Session expired, please log in again')
  }
  const { masterSalt } = reply.data
  const masterKey = await deriveMasterKey(email, password, masterSalt)
  const keychainReply = await api.get<{ Reply: KeychainReply }>('/keychain')
  const keychainKey = await decloakString(keychainReply.data.key, masterKey)
  keystore.set('keychainKey', keychainKey, claims.sessionExpiresAt)
  keystore.delete('credentials')
}

// --

function encodeCredentials({ email, password }: LoginCredentials) {
  return [utf8ToBase64(email), utf8ToBase64(password)].join(':')
}

function decodeCredentials(encoded: string): LoginCredentials {
  const [email, password] = encoded.split(':')
  return {
    email: base64toUTF8(email),
    password: base64toUTF8(password)
  }
}
