import {
  Button,
  FormControl,
  forwardRef,
  Stack,
  StackProps
} from '@chakra-ui/react'
import { Formik, FormikErrors } from 'formik'
import { Label, TwoFactorTokenField } from 'modules/ui/forms'
import React from 'react'

export interface Login2FAFormValues {
  token: string
}

const initialValues: Login2FAFormValues = {
  token: ''
}

export interface Login2FAFormProps extends Omit<StackProps, 'onSubmit'> {
  onSubmit: (values: Login2FAFormValues) => Promise<void>
}

export const Login2FAForm = forwardRef<Login2FAFormProps, 'div'>(
  ({ onSubmit, ...props }, ref) => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={values => {
          const errors: FormikErrors<Login2FAFormValues> = {}
          if (!values.token) {
            errors.token = 'Two factor authentication token required'
          } else if (!/^\d{6}$/.test(values.token)) {
            errors.token = 'Token should be six digits long'
          }
          return errors
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <>
            {/**
             * Why isn't there a <Form> component here?
             * See /docs/security/forms.md
             */}
            <Stack spacing={4} ref={ref} {...props}>
              <FormControl>
                <Label htmlFor="token" display="block" textAlign="center">
                  Two Factor Authentication Token
                </Label>
                <TwoFactorTokenField
                  isDisabled={isSubmitting}
                  name="token"
                  justifyContent="center"
                  my={8}
                  onComplete={() => {
                    // Fix a weird race condition within Formik when
                    // the internal state is lagging behind when running
                    // validation after `submitForm` is called, if a
                    // `setFieldValue` has been applied within the same tick.
                    // ie: give React time to save its internal state before
                    // attempting to submit.
                    setTimeout(submitForm, 1)
                  }}
                />
              </FormControl>
              <Button
                // todo: Replace by prompt to use recovery code
                type="submit"
                isLoading={isSubmitting}
                width="100%"
                colorScheme="accent"
                mt={2}
                onClick={submitForm}
              >
                Verify Two Factor Authentication
              </Button>
            </Stack>
          </>
        )}
      </Formik>
    )
  }
)
