import { RouteLink } from '@47ng/chakra-next'
import {
  Button,
  FormControl,
  forwardRef,
  Stack,
  StackProps,
  useColorModeValue
} from '@chakra-ui/react'
import type { LoginCredentials } from 'client/actions/auth/login'
import { keepQueryStringTo } from 'client/hooks/redirects'
import { Formik, FormikErrors } from 'formik'
import { EmailField, Label, PasswordField } from 'modules/ui/forms'
import { DimmedText } from 'modules/ui/typography/dimmedText'
import React from 'react'

const initialValues: LoginCredentials = {
  email: '',
  password: ''
}

export interface LoginCredentialsFormProps
  extends Omit<StackProps, 'onSubmit'> {
  onSubmit: (values: LoginCredentials) => Promise<void>
}

export const LoginCredentialsForm = forwardRef<
  LoginCredentialsFormProps,
  'div'
>(({ onSubmit, ...props }, ref) => {
  const signupLinkColor = useColorModeValue('gray.700', 'gray.200')
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={values => {
        const errors: FormikErrors<LoginCredentials> = {}
        if (!values.email) {
          errors.email = 'Email address is required'
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address'
        }
        if (values.password.length === 0) {
          errors.password = 'Password is required'
        }
        return errors
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <>
          {/**
           * Why isn't there a <Form> component here?
           * See /docs/security/forms.md
           */}
          <Stack spacing={4} ref={ref} {...props}>
            <FormControl>
              <Label htmlFor="email">Email</Label>
              <EmailField autoComplete="email" />
            </FormControl>
            <FormControl>
              <Label htmlFor="password">Master Password</Label>
              <PasswordField name="password" autoComplete="current-password" />
            </FormControl>
            <Button
              type="submit"
              isLoading={isSubmitting}
              width="100%"
              colorScheme="accent"
              mt={2}
              onClick={submitForm}
            >
              Log into your account
            </Button>
            <DimmedText textAlign="center">
              Don't have an account?{' '}
              <RouteLink
                to={keepQueryStringTo('/signup').toString()}
                textDecoration="underline"
                color={signupLinkColor}
              >
                Sign up
              </RouteLink>
            </DimmedText>
          </Stack>
        </>
      )}
    </Formik>
  )
})
